import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'

import kidsImg from '../../images/train/kids.jpg';

export default function KidsYouth() {
    const sliderImages = [
        kidsImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Specialized Training" backLink="/specialized-training">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Kids and Youth</h2>
                                        <h3 className={pageStyles.subtitle}>TRAIN TO LEARN — ON RAMP PROGRAM</h3>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            We believe that kids and youth need to be taught body orientation, movement awareness and proper exercise and training techniques. This is done using thorough attention to detail, and easily understood coaching cues. The following training components are taught to participants:
                                            <br /><br />
                                            speed and agility drills<br />
                                            how to fall properly <br />
                                            how to understand posture, and how to move accordingly <br />
                                            primal movement and body awareness <br />
                                            injury prevention <br />
                                            training for pleasure vs training to compete <br />
                                            <br />
                                            We start accepting kids into our on-ramp program at the age of 6.
                                            <br /><br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
